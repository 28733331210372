import loadable from '@loadable/component';
import CoreTabs from '@nrk/core-tabs/jsx';
import { useAppState } from '../../app/contexts/AppStateContext';
import { PageRenderingError } from '../../app/errors';
import { createPageUrl } from '../../app/redirects';
import settings from '../../app/settings';
import { ComponentErrorBoundary } from '../../components/ComponentErrorBoundary/ComponentErrorBoundary';
import { Heading } from '../../components/Heading/Heading';
import { LastUpdated } from '../../components/LastUpdated/LastUpdated';
import { Loader } from '../../components/Loader/Loader';
import { NowHero } from '../../components/NowHero/NowHero';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import { SaveAs } from '../../components/SaveAs/SaveAs';
import { IView, ViewSwitcher } from '../../components/ViewSwitcher/ViewSwitcher';
import { useFetchCelestialEventsMultipleDaysByLocationId } from '../../data/celestialEvents/hooks';
import { useFetchCurrentHourByLocationId } from '../../data/currentHour/hooks';
import { useFetchForecastByLocationId } from '../../data/forecast/hooks';
import { useFetchLocation } from '../../data/locations/hooks';
import { useFetchNowcastByLocationId } from '../../data/nowcast/hooks';
import { useFetchWarningsByLocationId } from '../../data/warnings/hooks';
import { useCurrentLocationId } from '../../lib/hooks';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { template } from '../../lib/string';
import { IForecast } from '../../model/forecast';
import { ITranslateFunction } from '../../model/translate';
import { IWarning } from '../../model/warning';
import { mapWarningsToIntervals } from '../../modelHelpers/warning';
import './ForecastPage.scss';

const DailyWeatherList = loadable(() => import('../../components/DailyWeatherList/DailyWeatherList'));
const Meteogram = loadable(() => import('../../components/Meteogram/Meteogram'));
const HourlyWeatherDialog = loadable(() =>
  import(/* webpackPrefetch: true */ '../../components/HourlyWeatherDialog/HourlyWeatherDialog')
);

export default function ForecastPage() {
  const { currentPage } = useAppState();
  const locationId = useCurrentLocationId();
  const translate = useTranslate();
  const localeCode = useLocaleCode();
  const { data: location, isLoading: locationIsLoading } = useFetchLocation({ locationId });
  const { data: forecast, isLoading: forecastIsLoading } = useFetchForecastByLocationId({
    locationId
  });
  const { data: currentHour, isLoading: currentHourIsLoading } = useFetchCurrentHourByLocationId({ locationId });
  const { data: nowcast, isLoading: nowcastIsLoading } = useFetchNowcastByLocationId({ locationId });
  const { data: warningList } = useFetchWarningsByLocationId({ locationId });
  const { data: celestialEvents } = useFetchCelestialEventsMultipleDaysByLocationId({
    locationId
  });

  const { subpageId } = currentPage.details.params;
  const { i: index } = currentPage.details.query;

  if (locationIsLoading || forecastIsLoading || currentHourIsLoading || nowcastIsLoading) {
    return (
      <PageRoot>
        <Loader type="block" />
      </PageRoot>
    );
  }

  if (subpageId == null || location == null || forecast == null) {
    throw new PageRenderingError();
  }

  const warningsGroupedByDay =
    warningList != null ? groupWarningsByDay({ forecast, warnings: warningList.warnings }) : [];

  const shouldRenderGraph = subpageId === 'graph';
  const shouldRenderDailyTable = ['daily-table', 'hourly-table'].includes(subpageId);

  const dailyTableUrl = location
    ? createPageUrl({
        localeCode,
        pageId: 'forecast',
        subpageId: 'daily-table',
        locationId: location.id,
        urlPath: location.urlPath
      })
    : '';

  const graphUrl = location
    ? createPageUrl({
        localeCode,
        pageId: 'forecast',
        subpageId: 'graph',
        locationId: location.id,
        urlPath: location.urlPath
      })
    : '';

  const printPath = template(settings.internal.printPathTemplate[localeCode], {
    locationId,
    urlPath: location.urlPath
  });
  const printUrl = `${settings.site.contentBaseUrl}/${localeCode}/${printPath}`;

  const svgPath = template(settings.internal.svgPathTemplate[localeCode], {
    locationId,
    urlPath: location.urlPath
  });
  const svgUrl = `${settings.site.contentBaseUrl}/${localeCode}/${svgPath}`;

  const views: IView[] = [
    {
      id: 'table',
      for: 'forecast-page__table',
      displayName: translate('nav/table'),
      href: dailyTableUrl
    },
    {
      id: 'graph',
      for: 'forecast-page__graph',
      displayName: translate('nav/graph'),
      href: graphUrl
    }
  ];

  const activeViewId = shouldRenderDailyTable ? views[0].id : views[1].id;

  return (
    <PageRoot
      className="forecast-page"
      renderModalChildren={() => {
        if (forecast == null || subpageId == null) {
          return null;
        }

        const isHourly = subpageId === 'hourly-table';
        if (isHourly === true) {
          return (
            <HourlyWeatherDialog
              index={index}
              location={location}
              forecast={forecast}
              warnings={warningsGroupedByDay[index]}
              celestialEvents={celestialEvents}
            />
          );
        }

        return null;
      }}
    >
      {/*
        This error boundary doesn't work when the forecast page is rendered on the server,
        but at least the forecast page won't fail when navigating to a location after the
        app has loaded.
      */}
      {currentHour != null && (
        <ComponentErrorBoundary>
          <NowHero location={location} currentHour={currentHour} nowcast={nowcast} />
        </ComponentErrorBoundary>
      )}

      <div className="layout-container">
        <CoreTabs className="forecast-page__tabs">
          <ViewSwitcher
            className="forecast-page__view-switcher"
            width="compact"
            views={views}
            activeViewId={activeViewId}
          />
        </CoreTabs>

        <div className="forecast-page__tab-content">
          <div className="forecast-page__table" id="forecast-page__table" hidden={activeViewId !== 'table'}>
            {shouldRenderDailyTable && (
              <>
                {renderTitle(forecast, translate)}
                <DailyWeatherList
                  warnings={warningsGroupedByDay}
                  location={location}
                  forecastDayIntervals={forecast.dayIntervals}
                />
              </>
            )}
          </div>

          <div className="forecast-page__graph" id="forecast-page__graph" hidden={activeViewId !== 'graph'}>
            {shouldRenderGraph && <Meteogram forecast={forecast} />}
          </div>
        </div>

        <div className="forecast-page__footer">
          <LastUpdated created={forecast.created} type="forecast" />
          <div className="forecast-page__save-as">
            <SaveAs type="pdf" url={printUrl} />
            <SaveAs type="svg" url={svgUrl} />
          </div>
        </div>
      </div>
    </PageRoot>
  );
}

function renderTitle(forecast: IForecast | undefined, translate: ITranslateFunction) {
  return (
    <Heading level="h2" size="2" hidden={true}>
      {forecast == null || forecast.dayIntervals.length === 0
        ? ''
        : translate('forecastPage/title', { numberOfDays: forecast.dayIntervals.length })}
    </Heading>
  );
}

function groupWarningsByDay({ forecast, warnings }: { forecast: IForecast; warnings: IWarning[] }) {
  return mapWarningsToIntervals(forecast.dayIntervals, warnings);
}
